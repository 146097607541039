import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'

const Page404 = styled('div')`
  text-align: center;

  a {
    padding: 8px 10px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    background: #a18c78;
    color: #fff;
    text-decoration: none;
    border-radius: 6px;
    margin-bottom: 15px;
    display: inline-block;
    transition: 0.2s linear;

    &:hover {
      transform: scale(1.1);
    }
  }
`

const NotFoundPage = ({ location }) => {
  return (
    <Layout>
      <Helmet title={`Page Not Found`}>
        <link rel="canonical" href={`${location.href}`} />
      </Helmet>
      <Page404>
        <h1>404</h1>
        <h2>Oops, page not found!</h2>
        <p>
          Either something went wrong or the page you requested does not exist
          anymore.
        </p>
        <Link to="/">Continue Browsing</Link>
      </Page404>
    </Layout>
  )
}

export default NotFoundPage
